<template>
  <b-overlay
    :show="showLoading"
    rounded="sm"
    variant="white"
    opacity="0.50"
  >
    <b-card-actions
      ref="leadsListActionCard"
      :title="'Leads list (' + leadsList.length + ')'"
      action-refresh
      action-collapse
      @refresh="loadLeads"
    >

      <b-row>
        <b-col
          md="2"
          sm="4"
          class="my-1"
        >
          <b-form-group
            class="mb-0"
          >
            <label class="d-inline-block text-sm-left mr-50">Per page</label>
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              :options="pageOptions"
              class="w-50"
            />
          </b-form-group>
        </b-col>
        <b-col
          md="2"
          sm="4"
          class="my-1"
        >
          <download-excel
            v-if="$can('manage', 'all')"
            :data="leadsList"
            :fields="json_fields"
            worksheet="Leads Sheet"
            name="leads.xls"
            style="background: #bad000;
          cursor: pointer;
          box-shadow: 2px 4px #e6e6e6;
    color: white;
    border: none;
    border-radius: 10px;
    padding: 10px; text-align: center"
          >
            Export Excel
          </download-excel>
        </b-col>
        <b-col
          md="6"
          class="my-1"
        >
          <b-form-group
            label="Filter"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="filterInput"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              />
              <b-input-group-append>
                <b-button
                  :disabled="!filter"
                  @click="filter = ''"
                >
                  Clear
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="leadsList"
        responsive
        :fields="$can('manage', 'all') ? adminFields : fields"
        primary-key="id"
        show-empty
        striped
        hover
        :per-page="perPage"
        :current-page="currentPage"
        :filter="filter"
        :filter-included-fields="filterOn"
        empty-text="No matching records found"
      >

        <!-- Column: User -->
        <template #cell(name)="data">
          <b-media vertical-align="center">
            <b-link
              :to="{ name: 'user_data', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap text-dark"
              style="text-decoration: underline"
            >
              {{ data.item.name }}
            </b-link>
            <small class="text-muted">{{ data.item.lead.subtitle }}</small>
          </b-media>
        </template>

        <template #cell(lead.agentName)="data">
          <b-media vertical-align="center">
            <b-link
              :to="{ name: 'user_data', params: { id: data.item.lead.agent_id } }"
              class="font-weight-bold d-block text-nowrap text-dark"
              style="text-decoration: underline"
            >
              {{ data.item.lead.agentName }}
            </b-link>
          </b-media>
        </template>

        <template #cell(lead.brokerName)="data">
          <b-media vertical-align="center">
            <b-link
              :to="{ name: 'user_data', params: { id: data.item.lead.brokerID } }"
              class="font-weight-bold d-block text-nowrap text-dark"
              style="text-decoration: underline"
            >
              {{ data.item.lead.brokerName }}
            </b-link>
          </b-media>
        </template>

        <template #cell(status)="data">
          <b-badge
            pill
            :variant="'light-' + data.item.lead.statusColor"
          >
            <span>{{ data.item.lead.status }}</span>
          </b-badge>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
          <!--          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>-->
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="leadsList.length"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card-actions>
  </b-overlay>
</template>

<script>
// eslint-disable-next-line import/extensions
import BCardActions from '@core/components/b-card-actions/BCardActions'
import {
  BTable,
  BCol,
  BRow,
  BPagination,
  BLink,
  BMedia,
  BBadge,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BButton,
  BOverlay,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import { avatarText } from '@core/utils/filter'

export default {
  name: 'Leads',
  components: {
    BTable,
    BCol,
    BRow,
    BLink,
    BMedia,
    BBadge,
    BPagination,
    BCardActions,
    BFormGroup,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    BButton,
    BFormInput,
    BOverlay,
  },

  data() {
    return {
      perPage: 10,
      pageOptions: [5, 10, 15, 25, 50, 75, 100, 'All'],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      avatarText,
      filter: null,
      filterOn: [],
      fields: [
        { key: 'name', label: 'LEAD', sortable: true },
        { key: 'lead.agentName', label: 'ADVISOR', sortable: true },
        { key: 'lead.brokerName', label: 'BROKER', sortable: true },
        { key: 'lead.lives', label: 'LIVES', sortable: true },
        { key: 'status', label: 'STATUS', sortable: true },
        { key: 'created', label: 'CREATED', sortable: true },
      ],
      adminFields: [
        { key: 'name', label: 'LEAD', sortable: true },
        { key: 'mobile', label: 'MOBILE', sortable: true },
        { key: 'lead.agentName', label: 'ADVISOR', sortable: true },
        { key: 'lead.brokerName', label: 'BROKER', sortable: true },
        { key: 'lead.lives', label: 'LIVES', sortable: true },
        { key: 'status', label: 'STATUS', sortable: true },
        { key: 'created', label: 'CREATED', sortable: true },
      ],
      json_fields: {
        Created: 'createdExcel',
        Name: 'name',
        Mobile: 'mobile',
        Advisor: 'lead.agentName',
        Broker: 'lead.brokerName',
        Lives: 'lead.lives',
        Status: 'lead.status',
      },
      showLoading: false,
    }
  },
  computed: {
    ...mapGetters({
      leadsList: 'users/leadsList',
    }),
  },
  async created() {
    this.showLoading = true
    await this.loadLeads()
    this.showLoading = false
  },
  methods: {
    ...mapActions({
      loadAllLeads: 'get',
    }),
    async loadLeads() {
      await this.loadAllLeads({
        storeName: 'users',
        url: '/admin/users/leads',
        commitUrl: 'users/SET_LEADS',
      })
      this.$refs.leadsListActionCard.showLoading = false
    },
    resolveUserStatusVariant(status) {
      if (status === true) return 'success'
      if (status === false) return 'danger'
      return 'primary'
    },
    resolveUserStatusVariantText(status) {
      if (status === true) return 'Active'
      if (status === false) return 'Inactive'
      return 'Active'
    },
    resolveUserStatusVariantIcon(status) {
      if (status === true) return 'CheckIcon'
      if (status === false) return 'XIcon'
      return 'CheckIcon'
    },
  },
}
</script>

<style scoped>

</style>
